import React from "react";

/**
 *
 */
type Content = {
    id : string
    header : string
    content : Content[]
};

/**
 *
 */
type Data = {
    content : Content[]
    level ?: number
};

/**
 *
 * @constructor
 */
export default function TableOfContents ({ content, level = 0 } : Data) {
    // render
    return (
        <>
            <ol className={'list-group list-group-numbered bg-light' + (0 === level ? '' : ' ps-4')}>
                {content.map((item, index) =>
                    <>
                        <li key={'toc-li-' + item.id}>
                            <a className={'table-of-content'} href={'#' + item.id}>{item.header}</a>
                            <TableOfContents content={item.content} level={index + 1} />
                        </li>
                    </>
                )}
            </ol>
        </>
    );
}