import Link from 'next/link';
import InsertHTML from "./insertHTML";
import React from "react";
import Media from "./media";

/**
 *
 * @param id
 * @param level
 * @param type
 * @param header
 * @param text
 * @param media
 * @param cta
 * @returns {JSX.Element}
 * @constructor
 */
export default function Block ({ id, level, type, header, text, media, cta }) {
    // init
    let generalStyle = "p-5 d-xxl-flex"
    let contentStyle = 'm-auto mt-5 mt-xxl-0 col-12 col-md-11 col-lg-9 col-xl-8 col-xxl-5'
    let mediaStyle = 'm-auto col-12 col-md-11 col-lg-9 col-xl-8 col-xxl-6'
    let ctaStyle = "btn btn-lg w-auto"

    if('green' === type) {
        generalStyle += " text-light bg-primary"
        ctaStyle += " btn-outline-light"
    } else if('white' === type) {
        generalStyle += " bg-light"
        contentStyle += ' order-xxl-0'
        mediaStyle += ' order-xxl-1'
        ctaStyle += " btn-primary"
    }

    // render
    return (
        <>
            <div key={id} id={id} className={generalStyle}>

                {/* Media */}
                <div className={mediaStyle}>
                    {media ? <Media data={media} figure={false} /> : ''}
                </div>

                {/* Content */}
                <div className={contentStyle}>
                    {/* Heading */}
                    {
                        1 === level
                            ? <h1 className="mb-5"><InsertHTML content={header} style={'text-center text-xxl-start' + ('green' === type ? ' text-white' : '')} /></h1>
                            : <h2 className="mb-5"><InsertHTML content={header} style={'text-center text-xxl-start' + ('green' === type ? ' text-white' : '')} /></h2>
                    }

                    {/* Text */}
                    <div className="mb-5">
                        <InsertHTML content={text} style={'text-center text-xxl-start' + ('green' === type ? ' text-white' : '')} />
                    </div>

                    {/* CTA */}
                    {cta ? (<div className="row justify-content-center justify-content-xxl-start pt-2 ps-xxl-2 mb-4">
                        <Link href={cta.url} className={ctaStyle}>
                            {cta.text}
                        </Link>
                    </div>) : null}
                </div>
            </div>
        </>
    )
}