import React, {useEffect, useState} from "react";
import {useTranslation} from "next-i18next";

/**
 *
 * @param imageURL
 * @param url
 * @param title
 * @param description
 * @param outline
 * @param showText
 * @returns {JSX.Element}
 * @constructor
 */
export default function Share ({ imageURL = null, url = null, title = null, description = null, outline = true, showText = true }) {
    // init variables
    const [visible, setVisible] = useState(false);
    imageURL = imageURL ?? process.env.logoURL;

    // initialize
    useEffect(
        function() {
            // if share-button functionality is supported show the button
            if (navigator.share) {
                setVisible(true);
            }
        },
        []
    );

    // share-callback
    async function share(event) {
        event.preventDefault();

        // check if share-functionality is supported
        if (!navigator.share) {
            //console.log('Share-functionality is not supported in this browser.');
            return;
        }

        // use current url. get canonical url or fallback if not exist
        if (!url) {
            const canonicalElement = document.querySelector('link[rel=canonical]');
            if (canonicalElement) {
                url = canonicalElement.href;
            } else {
                url = document.location.href;
            }
        }

        // create share data
        let shareData = {
            title: title ?? document.querySelector('title').textContent,
            text: description ?? document.querySelector('meta[name=description]').attributes['content'].value,
            url: url
        };

        // test if image is supported
        if (navigator.canShare && navigator.canShare({image: imageURL, url: url})) {
            // add image to the data
            shareData.image = imageURL;
        }

        // actually share
        await navigator.share(shareData);
    }

    // init translation
    const { t } = useTranslation();

    // render
    return (
        <>
            <button className={(visible ? '' : 'd-none ') + "w-auto border-0 bg-transparent text-primary p-0" + (showText ? ' ms-3 me-3' : '')} onClick={share}>
                {
                    outline ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-share" viewBox="0 0 16 16">
                            <path
                                d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
                        </svg>
                    :
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-share-fill" viewBox="0 0 16 16">
                            <path
                                d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
                        </svg>
                }
                {showText ? <p className="mt-1 small">{t('share')}</p> : ''}
            </button>
        </>
    );
}