import Share from "../share";
import React from "react";
import {useTranslation} from "next-i18next";

/**
 *
 * @param date
 * @param image
 * @param url
 * @param title
 * @param description
 * @returns {JSX.Element}
 * @constructor
 */
export default function SubHeader({ date, image = null, url = null, title = null, description = null }) {
    // init
    const { t } = useTranslation();

    // render
    return (
        <>
            {/* Share */}
            <span className={'small text-muted me-2'}>{t('updated') + ': ' + date}</span>

            {/* Share */}
            <Share
                imageURL={image}
                url={url}
                title={title}
                description={description}
                outline={false}
                showText={false}
            />
        </>
    );
}