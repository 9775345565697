import InsertHTML from "../insertHTML";
import MetaData, {Type} from "../metadata";
import React from "react";
import Media from "../media";
//import TableOfContents from "../tableOfContents";
//import {useTranslation} from "next-i18next";

/**
 *
 * @param date
 * @param header
 * @param title
 * @param description
 * @param keywords
 * @param content
 * @returns {JSX.Element}
 * @constructor
 */
export default function BlankPage ({ date, header, title, description, keywords, content }) {
    // init
    //const { t } = useTranslation();

    // render
    return (
        <>
            {/* Metadata */}
            <MetaData
                type={Type.Article}
                title={title}
                description={description}
                keywords={keywords}
                imageURL={process.env.logoURL}
                canonical={false}
                urlOnlyForThisCountry={false}
            />

            {/* Header */}
            <h1 className="text-center text-md-start fw-bold mt-2">{header}</h1>

            {/* Table of contents */}
            {/*<div className={'mt-5 mb-1'}>
                <p className={'mb-1'}><b>{t('table.of.contents')}</b></p>
                <TableOfContents content={content} />
            </div>*/}

            {/* Body */}
            {content.map((item, index) =>
                <div id={item.id} key={'item-' + index}>
                    <h2 className="mt-5">{item.header}</h2>
                    {
                        item.media ?
                            <Media data={ {...item.media, ...{'priority': false}} }  figure={true} />
                            : ''
                    }
                    <InsertHTML content={item.text} />
                    {item.content.map((itemChild, childIndex) =>
                        <div id={itemChild.id} key={'child-' + index + '-' + childIndex}>
                            <h3 className="mt-3">{itemChild.header}</h3>
                            {
                                itemChild.media ?
                                    <Media data={ {...itemChild.media, ...{'priority': false}} } figure={true} />
                                    : ''
                            }
                            <InsertHTML content={itemChild.text} />
                            {itemChild.content.map((itemChildChildren, childChildrenIndex) =>
                                <div id={itemChildChildren.id} key={'childchildren-' + index + '-' + childIndex + '-' + childChildrenIndex}>
                                    <h4 className="mt-2">{itemChildChildren.header}</h4>
                                    {
                                        itemChildChildren.image ?
                                            <Media data={ {...itemChildChildren.media, ...{'priority': false}} } figure={true} />
                                            : ''
                                    }
                                    <InsertHTML content={itemChildChildren.text} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}