import BlankPage from "./templates/blank";
import BlockPage from "./templates/block";
import BlogPost from "./templates/blog";
import Index from "./templates/index";

/**
 *
 * @param type
 * @param date
 * @param data
 * @param embed
 * @returns {JSX.Element}
 * @constructor
 */
export default function Page ({ type, date, data, embed }) {
    if('blank' === type) {
        return  <BlankPage
                    date={date}
                    header={data.header}
                    title={data.title ?? data.header}
                    description={data.description ?? data.content.substring(0, 160)}
                    keywords={data.keywords ?? data.header.split(' ')}
                    content={data.content}
                    embed={embed}
                    namespace={data.namespace}
                />
    } else if ('block' === type) {
        return  <BlockPage
                    date={date}
                    blocks={data.content}
                    title={data.title ?? data.content[0].header}
                    description={data.description ?? data.content[0].text.substring(0, 160)}
                    keywords={data.keywords ?? data.content[0].header.split(' ')}
                    embed={embed}
                    namespace={data.namespace}
                />
    } else if ('blog' === type) {
        return  <BlogPost
                    date={date}
                    breadcrumb={data.breadCrumb}
                    header={data.header}
                    media={data.media}
                    title={data.title ?? data.header}
                    description={data.description ?? data.content.substring(0, 160)}
                    keywords={data.keywords ?? data.header.split(' ')}
                    content={data.content}
                    recommendations={data.recommendations}
                    embed={embed}
                    namespace={data.namespace}
                />
    } else if ('index' === type) {
        return  <Index
                    date={date}
                    breadcrumb={data.breadCrumb}
                    header={data.header}
                    media={data.media}
                    title={data.title ?? data.header}
                    description={data.description ?? data.header}
                    keywords={data.keywords ?? data.header.split(' ')}
                    posts={data.posts}
                    pagination={data.pagination}
                    embed={embed}
                    namespace={data.namespace}
                />
    } else {
        return <p>Undefined Template-Type {type}</p>
    }
}