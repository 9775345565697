import Link from 'next/link';
import InsertHTML from "../insertHTML";
import MetaData from "../metadata";
import React from "react";
import {useTranslation} from "next-i18next";
import Breadcrumb from "../breadcrumb";
import Media from "../media";
import SubHeader from "../blog/subHeader";
import Article from "../structuredData/article";
import Speakable from "../structuredData/speakable";
import {useRouter} from "next/router";
import Util from "../../lib/util";
//import Feedback from "../feedback";
import TableOfContents from "../tableOfContents";

/**
 *
 * @param lvl1
 * @param lvl2
 * @param lvl3
 * @returns {string}
 */
/*function createIndex(lvl1, lvl2, lvl3) {
    return (lvl1 + 1) + '.' + (null !== lvl2 ? lvl2 + 1 + '.' : '') + (null !== lvl3 ? lvl3 + 1 + '.' : '');
}*/

/**
 *
 * @param date
 * @param breadcrumb
 * @param header
 * @param media
 * @param title
 * @param description
 * @param keywords
 * @param content
 * @param recommendations
 * @param embed
 * @param namespace
 * @returns {JSX.Element}
 * @constructor
 */
export default function BlogPost ({ date, breadcrumb, header, media, title, description, keywords, content, recommendations, embed, namespace }) {
    // init
    const { t } = useTranslation();
    const thumbnail = process.env.imageURL + ('image' === media.type ? media.url : media.thumbnail);
    const router = useRouter();
    const utils = new Util();

    // render
    return (
        <>

            {/* Metadata */}
            {
                embed ? '' :
                    <MetaData
                        type={'article'}
                        title={title}
                        description={description}
                        keywords={keywords}
                        imageURL={thumbnail}
                    />
            }

            {/* Structured-Data */}
            {
                embed ? '' :
                    <Article
                        title={title}
                        published={date.published}
                        updated={date.updated}
                        imageURL={thumbnail}
                    />
            }
            {
                embed ? '' :
                    <Speakable
                        title={title}
                        description={description}
                        url={process.env.url + router.asPath}
                    />
            }

            {/* Breadcrumb */}
            {
                embed ? '' : <Breadcrumb items={breadcrumb}/>
            }

            {/* Image */}
            <div className={'mt-3 mb-3'}>
                <Media data={{...media, ...{'priority':true}}} figure={true} />
            </div>

            {/* Header */}
            {
                embed
                    ? <h2 className="h1 font-weight-bold">{header}</h2>
                    : <h1 className="font-weight-bold">{header}</h1>
            }

            {/* Date and Share */}
            <p className="font-weight-light">
                <SubHeader
                    date={date.updated}
                    image={thumbnail}
                    url={embed ? utils.createURL(router.locale, namespace) : null}
                    title={title}
                    description={description}
                />
            </p>

            {/* Lead */}
            <p className="lead">{description}</p>

            {/* Table of contents */}
            <div className={'mt-5 mb-1'}>
                <p className={'mb-1'}><b>{t('table.of.contents')}</b></p>
                <TableOfContents content={content} />
            </div>

            {/* Content */}
            {content.map((item) =>
                <div key={item.id} className={''}>
                    {embed ? <h3 id={item.id} className={'h2 mt-5'}>{item.header}</h3> : <h2 id={item.id} className={'mt-5'}>{item.header}</h2>}
                    {item.media ? <Media data={{...item.media, ...{'priority':false}}} figure={true} /> : '' }
                    <InsertHTML content={item.text} />
                    {item.content.map((itemChild) =>
                        <div key={itemChild.id}>
                            {embed ? <h4 id={itemChild.id} className={'h3 mt-4'}>{itemChild.header}</h4> :  <h3 id={itemChild.id} className={'mt-4'}>{itemChild.header}</h3>}
                            {itemChild.media ? <Media data={{...itemChild.media, ...{'priority':false}}} figure={true} /> : '' }
                            <InsertHTML content={itemChild.text} />
                            {itemChild.content.map((itemChildChildren) =>
                                <div key={itemChildChildren.id}>
                                    {embed ? <h5 id={itemChildChildren.id} className={'h4 mt-3'}>{itemChildChildren.header}</h5> : <h4 id={itemChildChildren.id} className={'mt-3'}>{itemChildChildren.header}</h4>}
                                    {itemChildChildren.media ? <Media data={{...itemChildChildren.media, ...{'priority':false}}} figure={true} /> : '' }
                                    <InsertHTML content={itemChildChildren.text} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}

            {/* Similar posts */}
            <h2 className="h3 mb-3 pt-4 mt-5">{recommendations.length > 0 ? t('similar.posts') : ''}</h2>
            <div className="row row-cols-1 row-cols-md-3">
                {recommendations.map((recommendation, index) =>
                    <div key={index} className={'col mb-5'}>
                        <Link href={recommendation.url} className={'card h-100 text-decoration-none text-dark bg-light border-0'}>
                            <div className="card-img-top">
                                <Media data={recommendation.media} figure={false} />
                            </div>
                            <div className="card-body">
                                <h3 className="h4 card-title">{recommendation.title}</h3>
                                <p className="card-text">{recommendation.description}</p>
                            </div>
                            <div className="card-footer bg-light border-0">
                                <SubHeader
                                    date={recommendation.date.updated}
                                    image={process.env.imageURL + recommendation.media.url}
                                    url={utils.createURL(router.locale, recommendation.url)}
                                    title={recommendation.title}
                                    description={recommendation.description}
                                />
                            </div>
                        </Link>
                    </div>
                )}
            </div>
        </>
    )
}