import { useTranslation } from 'next-i18next';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Page () {
    const { t } = useTranslation();

    return (
        <>
            <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-5" role="status">
                    <span className="visually-hidden">{t('loading')}</span>
                </div>
            </div>
        </>
    )
}