import Link from 'next/link';
import Pagination from "../pagination";
import MetaData, {Type} from "../metadata";
import React from "react";
import Breadcrumb from "../breadcrumb";
import Media from "../media";
import SubHeader from "../blog/subHeader";
import {useRouter} from "next/router";
import Util from "../../lib/util";

/**
 *
 * @param date
 * @param breadcrumb
 * @param header
 * @param title
 * @param media
 * @param description
 * @param keywords
 * @param posts
 * @param pagination
 * @param embed
 * @param namespace
 * @returns {JSX.Element}
 * @constructor
 */
export default function Index ({ date, breadcrumb, header, title, media, description, keywords, posts, pagination, embed, namespace }) {
    // init
    const thumbnail = process.env.imageURL + ('image' === media.type ? media.url : media.thumbnail);
    const router = useRouter();
    const utils = new Util();

    // render
    return (
        <>
            {/* Metadata */}
            {
                embed ? '' :
                <MetaData
                    type={Type.Website}
                    title={title}
                    description={description}
                    keywords={keywords}
                    imageURL={thumbnail}
                />
            }

            {/* Breadcrumb */}
            {
                embed ? '' : <Breadcrumb items={breadcrumb}/>
            }

            {/* Header */}
            {
                embed ?
                    <h2 className="h1 fw-bold">{header}</h2>
                    :
                    <h1 className="fw-bold">{header}</h1>
            }

            {/* Date & Share */}
            <p className="font-weight-light">
                <SubHeader
                    date={date.updated}
                    image={thumbnail}
                    url={embed ? utils.createURL(router.locale, namespace) : null}
                    title={title}
                    description={description}
                />
            </p>

            {/* Lead */}
            <p className="lead text-md-start">{description}</p>

            {/* Content */}
            <div className="bg-light pt-4 pb-3">

                {/* Posts */}
                {posts.map((post, index) =>
                    <Link key={'post-' + index} href={post.url} className="row mb-3 text-decoration-none g-0">
                        <div className="col-12 col-md-4 pe-md-4">
                            <Media data={{...post.media, ...{'priority': (index < 4)}}} figure={false} />
                        </div>
                        <div className="col-12 col-md-8 mt-2 mt-md-0">
                            {/* Title */}
                            {
                                embed ?
                                    <h3 className={'h2'}>{post.title}</h3>
                                    :
                                    <h2 className={''}>{post.title}</h2>
                            }

                            {/* Date and Share */}
                            <p className="font-weight-light">
                                <SubHeader
                                    date={post.date.updated}
                                    image={media ? ('image' === post.media.type ? (process.env.imageURL + post.media.url) : (process.env.imageURL + post.media.thumbnail)) : process.env.logoURL}
                                    url={utils.createURL(router.locale, post.url)}
                                    title={post.title}
                                    description={post.description}
                                />
                            </p>

                            {/* Lead */}
                            <p className="text-dark">{post.description}</p>
                        </div>
                    </Link>
                )}
            </div>

            {/* Pagination */}
            {false === embed ?
                <div>
                    <Pagination
                        moreResults={pagination.moreResults}
                        resultCount={pagination.resultCount}
                        currentPage={pagination.currentPage}
                        entriesPerPage={pagination.entriesPerPage}
                    />
                </div>
                : ''
            }
        </>
    )
}