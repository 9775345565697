import {useEffect, useState} from "react";

/**
 *
 */
export default function useOrigin(): string {
    const [origin, setOrigin] = useState('')

    // on init
    useEffect(() => {
        setOrigin(typeof window !== 'undefined' && window.location.origin ? window.location.origin : '')
    }, [])

    return origin
}