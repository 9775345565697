import Block from "../block";
import MetaData, {Type} from "../metadata";
import React from "react";

/**
 *
 * @param date
 * @param blocks
 * @param title
 * @param description
 * @param keywords
 * @returns {JSX.Element}
 * @constructor
 */
export default function BlockPage ({ date, blocks, title, description, keywords }) {
    // render
    return (
        <>
            {/* Metadata */}
            <MetaData
                type={Type.Website}
                title={title}
                description={description}
                keywords={keywords}
                imageURL={process.env.imageURL + ('image' === blocks[0].media.type ? blocks[0].media.url : blocks[0].media.thumbnail)}
                urlOnlyForThisCountry={false}
                canonical={false}
            />

            {/* Content */}
            {blocks.map((block, index) =>
                <Block
                    key={'block-' + index}
                    id={block.id ?? ('block-' + index)}
                    level={0 === index ? 1 : 2}
                    type={0 === index % 2 ? 'white' : 'green'}
                    header={block.header}
                    text={block.text}
                    media={{...block.media, ...{ 'priority': (0 === index)}}}
                    cta={block.cta ? {"url": block.cta.url, "text": block.cta.text} : null}
                />
            )}
        </>
    )
}