import React from "react";
import Base from "./base";

/**
 *
 * @param title
 * @param published
 * @param updated
 * @param imageURL
 * @returns {string}
 */
function createStructuredData(
    title,
    published,
    updated,
    imageURL
)
{
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "headline": title,
        "datePublished": published,
        "dateModified": updated,
        "author": {
            "@type": "Organization",
            "url": "https://www.eqno.net/imprint",
            "name:": "EQNO"
        },
        "image": [
            imageURL
        ]
    };

    return JSON.stringify(structuredData);
}

/**
 *
 * @param title
 * @param published
 * @param updated
 * @param imageURL
 * @returns {JSX.Element}
 * @constructor
 */
export default function Article ({ title, published, updated, imageURL }) {
    // render
    return (
        <>
            {/* Structured-Data */}
            <Base
                id={'article-' + title}
                content={
                    createStructuredData(
                        title,
                        published,
                        updated,
                        imageURL
                    )
                }
            />
        </>
    );
}