import React, {useEffect} from "react";
import Script from 'next/script';

/**
 *
 * @param id
 * @param content
 * @returns {JSX.Element}
 * @constructor
 */
export default function Base ({ id, content }) {
    // // init
    // useEffect(
    //     function () {
    //         const script = document.createElement("script")
    //         script.setAttribute('id', id)
    //         script.setAttribute('type', 'application/ld+json')
    //         script.textContent = content
    //         document.body.appendChild(script)
    //
    //         // clean up
    //         return () => {script?.parentNode?.removeChild(script)}
    //     },
    //     [id, content]
    // )

    // render
    return (
        <>
            {/* Structured-Data */}
            <Script
                id={'sd-' + id.replace(/ /g, '-').toLowerCase()}
                type={'application/ld+json'}
                strategy={'lazyOnload'}
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            />
        </>
    );
}