import Link from 'next/link'
import { useRouter } from 'next/router'
import {useTranslation} from "next-i18next";

/**
 *
 * @param page
 * @returns {string}
 */
function CreateURL(page) {
    const router = useRouter()
    let query = router.query
    delete query.page
    delete query.slug
    delete query.id
    const paths = router.asPath.split('?', 1);
    let url = paths[0] + '?page=' + encodeURIComponent(page)
    for (const [key, value] of Object.entries(query)) {
        url += '&' + encodeURIComponent(key) + '=' + encodeURIComponent(value);
    }
    return url
}

/**
 *
 * @param currentPage
 * @param resultCount
 * @param moreResults
 * @returns {[]}
 */
function createPages(currentPage, resultCount, moreResults) {
    let pages = [];
    let index = Math.max(1, currentPage - 1)

    let active = currentPage === index
    pages.push({
        "index": index,
        "url": CreateURL(index),
        "active": active,
        "disabled": 0 === resultCount
    });

    if((currentPage !== 1) || moreResults) {
        index = index + 1
        active = currentPage === index
        pages.push({
            "index": index,
            "url": CreateURL(index),
            "active": active,
            "disabled": 1 === currentPage && false === moreResults
        });

        if(active && moreResults) {
            index = index + 1
            pages.push({
                "index": index,
                "url": CreateURL(index),
                "active": currentPage === index,
                "disabled": false === moreResults
            });
        }
    }

    return pages
}

/**
 *
 * @param pagination
 * @returns {JSX.Element}
 * @constructor
 */
export default function Pagination ({ currentPage, resultCount, moreResults, entriesPerPage }) {
    // init
    const { t } = useTranslation();

    // init variables
    const firstPage = CreateURL(1);
    const previousPage = CreateURL(Math.max(1, currentPage - 1));
    const nextPage = CreateURL(currentPage + 1);
    const pages = createPages(
        currentPage,
        resultCount,
        moreResults
    );
    const lastPageOffset = (resultCount / entriesPerPage) + 0.99;
    const lastPage = CreateURL(parseInt(lastPageOffset.toString()));

    // render
    return (
        <>
            <nav className="p-3 text-center" aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                    <li key={'page-' + 1} className={'page-item' + (currentPage > 1 ? '' : ' disabled')}>
                        <Link href={firstPage} className="page-link" aria-label={t('first.page')}>
                            <span aria-hidden="true">{'<<'}</span>
                        </Link>
                    </li>
                    <li key={'page-' + 2} className={'page-item' + (currentPage > 1 ? '' : ' disabled')}>
                        <Link href={previousPage} className="page-link" aria-label={t('previous.page')}>
                            <span aria-hidden="true">{'<'}</span>
                        </Link>
                    </li>
                    {pages.map((page, index) =>
                        <li key={'page-' + (2 + index)} className={'page-item' + (page.active ? ' active' : '') + (page.disabled ? ' disabled' : '')}>
                            <Link href={page.url} className="page-link" aria-label={t('page') + " " + page.index}>
                                {page.index}
                            </Link>
                        </li>
                    )}
                    <li key={pages.length + 2 + 2} className={'page-item' + (moreResults ? '' : ' disabled')}>
                        <Link href={nextPage} className="page-link" aria-label={t('next.page')}>
                            <span aria-hidden="true">{'>'}</span>
                        </Link>
                    </li>
                    <li key={pages.length + 2 + 3} className={'page-item' + (moreResults ? '' : ' disabled')}>
                        <Link href={lastPage} className="page-link" aria-label={t('last.page')}>
                            <span aria-hidden="true">{'>>'}</span>
                        </Link>
                    </li>
                </ul>
                <small>{t('resultWithCount', { count: resultCount })}</small>
            </nav>
        </>
    )
}