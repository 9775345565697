import React from "react";
import Base from "./base";

/**
 *
 * @param items
 */
function createStructuredData(items)
{
    let structuredData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": []
    };

    items.map((item, index) => {
        structuredData.itemListElement.push(
            {
                "@type": "ListItem",
                "position": index + 1,
                "name": item.name,
                "item": process.env.url + item.url
            }
        );
    });

    return JSON.stringify(structuredData);
}

/**
 *
 * @param id
 * @param items
 * @returns {JSX.Element}
 * @constructor
 */
export default function Breadcrumb ({ id, items }) {
    // render
    return (
        <>
            {/* Structured-Data */}
            <Base
                id={id}
                content={createStructuredData(items)}
            />
        </>
    );
}