import Img from "./image";

/**
 *
 */
export type MediaData = {
    type : string
    url : string
    description : string
    height : number
    width : number
    priority : boolean
};

/**
 *
 * @param data
 * @param figure
 * @returns {JSX.Element|string}
 * @constructor
 */
export default function Media({ data, figure = false } : { data : MediaData, figure : boolean}) {
    if(figure) {
        if('image' === data.type) {
            return (
                <figure className="figure">
                    <Img
                        url={data.url}
                        alt={data.description}
                        height={data.height}
                        width={data.width}
                        priority={data.priority}
                        figure={true}
                    />
                    <figcaption className="figure-caption small text-center">{data.description}</figcaption>
                </figure>
            )
        } else if('video' === data.type) {
            return (
                ''
            )
        }
    } else {
        if('image' === data.type) {
            return (
                <Img
                    url={data.url}
                    alt={data.description}
                    height={data.height}
                    width={data.width}
                    priority={data.priority}
                />
            )
        } else if('video' === data.type) {
            return (
                ''
            )
        }
    }
}