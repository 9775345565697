import Link from 'next/link';

/**
 *
 * @param content
 * @returns {*[]}
 */
function splitLinks(content) {
    let splittetText = [];

    const matches = content.matchAll(
        /(<a\s.*?>.*?<\/a>)/gmi
    );

    let start = 0;
    for (const match of matches) {
        splittetText.push(content.substring(start, match.index));
        start = match.index + match[0].length;
        splittetText.push(content.substring(match.index, start));
    }
    splittetText.push(content.substring(start, content.length));

    return splittetText;
}

/**
 *
 * @param content
 * @param style
 * @returns {JSX.Element}
 */
function generateHTML(
    content,
    style
) {
    return (<div className={style} dangerouslySetInnerHTML={{ __html: content }} />)
}

/**
 *
 *
 */
function generateLink(
    content,
    style
) {
    // get href
    let hrefMatches;
    hrefMatches = content.match(
        /.*href=\\*"(.*)\\*"/i
    );

    // get the content of the link
    const linkContent = content.match(
        /.*<a.*>(.*)<\/a>.*/i
    )

    // get the class of the link
    const classNames = content.match(
        /.*<a.*class=\\*"(.*?)\\*".*>.*<\/a>.*/i
    )

    // internal or external link
    const external = hrefMatches[1].includes('https://');

    let cssClass = style;
    if(classNames) cssClass = style ? (style + ' ' + (classNames[1])) : classNames[1];

    // render
    return(
        <>
            {
                external
                    ? // external
                    <a className={cssClass} href={hrefMatches[1]} target={'_blank'} rel={'noopener noreferrer nofollow'}>
                        {linkContent ? linkContent[1] : ''}
                    </a>
                    : // internal
                    <Link href={hrefMatches[1]} className={cssClass}>
                        {linkContent ? linkContent[1] : ''}
                    </Link>
            }
        </>
    )
}

/**
 *
 * @param content
 * @param style
 * @returns {JSX.Element}
 * @constructor
 */
export default function InsertHTML({ content, style = '' }) {
    const splittedLinks = splitLinks(content);
    const cssClass = '' === style ? null : style;

    // if there is no link in the content, then just generate the content
    if(1 === splittedLinks.length) {
        return generateHTML(
            content,
            cssClass
        )
    }

    // if there is a link in the content, surround it with the next/link element
    return (
    <div className={cssClass}>
        {splittedLinks.map((splittedLink) =>
            <>
             {splittedLink.match(/.*href=\\*"(.*)\\*"/i)
                ?
                    generateLink(
                        splittedLink,
                        cssClass
                    )
                :
                    <span className={cssClass} dangerouslySetInnerHTML={{ __html: splittedLink }} />
            }
            </>
        )}
    </div>
    )
}