import Link from "next/link";
import React from "react";
import BreadCrumbStructuredData from './structuredData/breadcrumb';
import {useTranslation} from "next-i18next";
import useOrigin from "../lib/hooks/useOrigin";

/**
 *
 * @param items
 * @param lastItemDeactivated
 * @returns {JSX.Element}
 * @constructor
 */
export default function Breadcrumb ({ items, lastItemDeactivated = true }) {
    // init
    const { t } = useTranslation();

    // render
    return (
        <>
            {/* Navigation*/}
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb horizontal-scroll m-0 p-0">

                    {/* Start-Page */}
                    <li key={'br_' + 0} className={"breadcrumb-item horizontal-scroll-item"}>
                        <Link href={'/'} className="text-decoration-none link-primary small">{t('start')}</Link>
                    </li>

                    {/* Navigational-Hierarchy */}
                    {items.map((item, index) =>
                        <li key={'br_' + index} className={"breadcrumb-item horizontal-scroll-item" + ((index === items.length - 2) ? ' active' : '')}>
                            {(lastItemDeactivated && index === items.length - 1)
                                ? <span className={'text-muted small'}>{item.name}</span>
                                : <Link href={item.url} className="text-decoration-none link-primary small">{item.name}</Link>
                            }
                        </li>
                    )}
                </ol>
            </nav>

            {/* Structured-Data */}
            {items.length ?
                <BreadCrumbStructuredData
                    id={'bc-' + (items.length ? items[items.length - 1].name.toLowerCase() : 42)}
                    items={items}
                />
                : ''
            }
        </>
    );
}