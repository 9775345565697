import React from "react";
import Base from "./base";

/**
 *
 * @param title
 * @param description
 * @param url
 * @returns {string}
 */
function createStructuredData(
    title,
    description,
    url
)
{
    const structuredData = {
        "@context": "https://schema.org/",
        "@type": "WebPage",
        "name": title,
        "speakable":
            {
                "@type": "SpeakableSpecification",
                "xPath": [
                    "/html/head/title",
                    "/html/head/meta[@name='description']/@content"
                ]
            },
        "url": url
    };

    return JSON.stringify(structuredData);
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Speakable ({ title, description, url }) {
    // render
    return (
        <>
            {/* Structured-Data */}
            <Base
                id={'speakable-' + title}
                content={
                    createStructuredData(
                        title,
                        description,
                        url
                    )
                }
            />
        </>
    );
}